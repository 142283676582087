*,
html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins-Regular', sans-serif;
}

html {
    height: 100%;
}

body {
    min-height: 100%;
}

*:before, *:after {
    box-sizing: inherit;
}
h1 {
    font-weight: normal;
}
a {
    text-decoration: none;
}
button {
    font-family: 'Poppins', sans-serif;
}

.translate-up {
  will-change: auto;
  animation-name: translateUp;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.translate-down {
  will-change: auto;
  transform: translate(0%, -100%);
  animation-name: translateDown;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.offset {
  /* pointer-events: none; */
  transform: translate(0%, -100%);
}

.non-offset {
  transform: translate(0%, 0%);
}

.scale-up {
  /* transform: scale(0.7); */
  will-change: auto;
  animation-name: scaleUp;
  animation-duration: 0.5s;
  animation-delay: 100ms;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.scale-down {
  /* transform: scale(0.7); */
  will-change: auto;
  animation-name: scaleDown;
  animation-duration: 0.5s;
  animation-delay: 100ms;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.move-left {
  /* transform: scale(0.7); */
  transform: translate(10%, 0%);
  animation-name: moveLeft;
  animation-duration: 0.15s;
  animation-delay: 100ms;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.move-right {
  /* transform: scale(0.7); */
  transform: translate(0%, 0%);
  animation-name: moveRight;
  animation-duration: 0.5s;
  animation-delay: 100ms;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes translateUp {
  from { transform: translate(0%, 0%); }
  to { transform: translate(0%, -100%); }
}

@keyframes translateDown {
  from { transform: translate(0%, -100%); }
  to { transform: translate(0%, 0%); }
}

@keyframes scaleUp {
  0%   { transform: scale(.9); opacity: 0;}
  100% { transform: scale(1); opacity: 1;}
}

@keyframes scaleDown {
  0%   { transform: scale(1); opacity: 1;}
  100% { transform: scale(0.9); opacity: 0;}
}

@keyframes moveRight {
  0%   { transform: translate(0%, 0%); }
  100% { transform: translate(10%, 0%);}
}

@keyframes moveLeft {
  0%   { transform: translate(10%, 0%); }
  100% { transform: translate(0%, 0%);}
}